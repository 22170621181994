import React from "react"

import { GatsbyImage, getImage } from "gatsby-plugin-image"
import PropTypes from "prop-types"
import styled from "styled-components"

const StyledImageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const FullWidthImage = ({ image }) => (
  <StyledImageWrapper>
    <GatsbyImage image={getImage(image)} layout="fullWidth" />
  </StyledImageWrapper>
)

FullWidthImage.propTypes = {
  image: PropTypes.shape({}).isRequired,
}

export default FullWidthImage
