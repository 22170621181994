import React, { useState } from "react"

// import { StaticImage } from "gatsby-plugin-image"
import PropTypes from "prop-types"
import { config, useSpring, animated } from "react-spring"
import styled from "styled-components"

import Container from "components/common/Container"
import Section from "components/common/Section"

const StyledDescription = styled.p`
  color: ${({ theme }) => theme.color.text.default};
  font-size: 1.6rem;
  text-align: center;
  padding: 0 3rem 3rem;
`

const StyledImageWrapper = styled.div`
  ${({ theme }) => `
   margin: 30px 0;

    img {
      width: 100%;
    }

  ${theme.mq.xs} {
    display: none
  }
  `}
`

const StyledImageWrapperMobile = styled.div`
  ${({ theme }) => `
  display: none;
   margin: 30px 0;
   text-align: center;

    img {
      width: 100%;
    }

  ${theme.mq.xs} {
    display: block
  }
  `}
`

const TEDescription = ({ data }) => {
  const [isVisible, setIsVisible] = useState(false)
  const slideSpring = useSpring({ config: config.stiff, y: isVisible ? 0 : 250, opacity: isVisible ? 1 : 0 })

  return (
    <Section animatedTitle={data.title} onIntersectChange={setIsVisible}>
      <Container>
        <animated.div
          style={{
            transform: `${slideSpring.y.interpolate(dy => `translate3d(0,${dy}px,0)`)}`,
            ...slideSpring,
          }}
        >
          <StyledDescription>{data.description}</StyledDescription>
          <StyledImageWrapper>
            <img alt="Team extension graph" loading="lazy" src={data.image.localFile.publicURL} />
          </StyledImageWrapper>
          <StyledImageWrapperMobile>
            <img alt="Team extension graph" loading="lazy" src={data.image.localFile.publicURL} />
            {/* <StaticImage
              alt="Team extension graph mobile"
              loading="lazy"
              placeholder="tracedSVG"
              src={data.image.localFile.publicURL}
            /> */}
          </StyledImageWrapperMobile>
          <StyledDescription>{data.description2}</StyledDescription>
        </animated.div>
      </Container>
    </Section>
  )
}

TEDescription.propTypes = {
  data: PropTypes.shape({
    page_title: PropTypes.string.isRequired,
    page_description: PropTypes.string.isRequired,
  }).isRequired,
}

export default TEDescription
