import React, { useState } from "react"

import PropTypes from "prop-types"
import { config, useSpring, animated } from "react-spring"
import styled from "styled-components"

import Container from "components/common/Container"
import Section from "components/common/Section"

const StyledList = styled.ul`
  ${({ theme }) => `
  margin: 0 auto 30px;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(2, 1fr);
  max-width: 1140px;
  color: ${theme.color.text.inverted};

  ${theme.mq.xxs} {
    grid-template-columns: repeat(1, 1fr);
  }
  `}
`

const StyledListItem = styled.li`
  ${({ theme }) => `
  padding: 20px;
  background-color: ${theme.color.cyan_300};
  font-size: 1.6rem;
  font-weight: 600;
  text-align: center;
  `}
`

const StyledDescription = styled.p`
  color: ${({ theme }) => theme.color.text.inverted};
  font-size: 1.6rem;
  text-align: center;
  padding: 0 3rem 3rem;
`

const TEBenefits = ({ data }) => {
  const [isVisible, setIsVisible] = useState(false)
  const slideSpring = useSpring({ config: config.stiff, y: isVisible ? 0 : 250, opacity: isVisible ? 1 : 0 })

  return (
    <Section animatedTitle={data.title} color="cyan" onIntersectChange={setIsVisible}>
      <Container>
        <animated.div
          style={{
            transform: `${slideSpring.y.interpolate(dy => `translate3d(0,${dy}px,0)`)}`,
            ...slideSpring,
          }}
        >
          <StyledList>
            {data.List.map(item => (
              <StyledListItem>{item.description}</StyledListItem>
            ))}
          </StyledList>
          <StyledDescription>{data.description}</StyledDescription>
        </animated.div>
      </Container>
    </Section>
  )
}

TEBenefits.propTypes = {
  data: PropTypes.shape({
    page_title: PropTypes.string.isRequired,
    page_description: PropTypes.string.isRequired,
  }).isRequired,
}

export default TEBenefits
