import React from "react"

import { graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"

import GeneralText from "components/common/GeneralText"
import TransitionWrapper from "components/common/Layout"
import Opinions from "components/common/Opinions"
import Seo from "components/common/Seo"
import SubpageHeader from "components/common/SubpageHeader"
import FullWidthImage from "components/pages/about/FullWidthImage"
import urls from "config/urls"

import CTASection from "../components/common/CTASection"
import TechStack from "../components/common/TechStack"
import TEBenefits from "../components/pages/team-extension/TEBenefits"
import TEDescription from "../components/pages/team-extension/TEDescription"

const query = graphql`
  query TeamExtensionQuery {
    strapiTeamExtension {
      page_title
      page_description
      image {
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      Contact {
        description
        title
      }
      Description {
        description
        description2
        title
        image {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      Benefits {
        List {
          description
        }
        description
        title
      }
    }
  }
`

const TeamExtension = ({ location }) => {
  const { strapiTeamExtension } = useStaticQuery(query)
  return (
    <TransitionWrapper location={location}>
      <Seo seoPageName="Team extension" />
      <SubpageHeader subpageTitle={strapiTeamExtension.page_title} />
      <GeneralText aboutContentTxt={strapiTeamExtension.page_description} />
      <FullWidthImage image={strapiTeamExtension.image.localFile} />
      <TEDescription data={strapiTeamExtension.Description} />
      <TEBenefits data={strapiTeamExtension.Benefits} />
      <Opinions />
      <CTASection data={strapiTeamExtension.Contact} to={urls.contact.url} />
      <TechStack />
    </TransitionWrapper>
  )
}

TeamExtension.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
}

export default TeamExtension
