import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"

const StyledSlide = styled.div`
  margin: 0 auto;
  max-width: 660px;
  padding: 0 50px 5rem;
  display: grid;
  grid-gap: 3rem;
  grid-template-columns: auto;
  align-items: center;
  justify-items: center;
  ${({ theme }) => theme.mq.xs} {
    grid-template-columns: auto;
    grid-gap: 3rem;
  }
`

const StyledQuote = styled.p`
  font-size: 1.7rem;
  font-weight: 500;
  font-style: italic;
  text-align: justify;
  grid-column: 1/3;

  ${({ theme }) => theme.mq.xs} {
    grid-column: unset;
    text-align: justify;
  }
`
const StyledSlideTxt = styled.p`
  font-size: 1.5rem;
  font-weight: 500;
  text-align: right;
  grid-column: 1/3;
  color: ${({ theme }) => theme.color.cyan_400};

  ${({ theme }) => theme.mq.xs} {
    grid-column: unset;
  }
`

const StyledSlideLogo = styled(GatsbyImage)`
  max-width: 150px;
`

export { StyledSlide, StyledQuote, StyledSlideTxt, StyledSlideLogo }
