import React, { useState } from "react"

import { graphql, useStaticQuery } from "gatsby"
import { config, useSpring, animated } from "react-spring"
import styled from "styled-components"

import Container from "components/common/Container"
import Section from "components/common/Section"

import Slider from "../Slider"
import { StyledSlide, StyledQuote, StyledSlideTxt } from "./Opinions.styles"

const query = graphql`
  query opinionsQuery {
    strapiCommon {
      reviews_section {
        section_title
        review_entities {
          id
          author
          content
          company
          logo {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 200, placeholder: TRACED_SVG)
              }
            }
          }
        }
      }
    }
    strapiHome {
      Video {
        video_url {
          localFile {
            absolutePath
            url
          }
        }
      }
    }
  }
`

const StyledVideo = styled.video`
  position: relative;
  width: 100%;
  max-width: 700px;
  display: block;
  margin: 0 auto;
`

const StyledReviewWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  max-width: 1440px;
  margin: 0 auto;
  ${({ theme }) => theme.mq.s} {
    flex-direction: column;
  }
`

const StyledContainer = styled(Container)`
  width: 50%;
  ${({ theme }) => theme.mq.m} {
    width: 100%;
  }
`

const Opinions = props => {
  const { isVideo } = props
  const { strapiCommon, strapiHome } = useStaticQuery(query)
  const [isVisible, setIsVisible] = useState(false)
  const slideSpring = useSpring({ config: config.stiff, y: isVisible ? 0 : 250, opacity: isVisible ? 1 : 0 })

  return (
    <Section animatedTitle={strapiCommon.reviews_section.section_title} intersected onIntersectChange={setIsVisible}>
      <animated.div style={{ transform: slideSpring.y.interpolate(dy => `translate3d(0,${dy}px,0)`), ...slideSpring }}>
        <StyledReviewWrapper>
          <StyledContainer>
            <Slider chevronY={-100} hideArrowsOnDesktop variant="cyan">
              {strapiCommon.reviews_section.review_entities.map(({ content, author, id }) => (
                <StyledSlide key={id}>
                  <StyledQuote>{content}</StyledQuote>
                  <StyledSlideTxt>{author}</StyledSlideTxt>
                </StyledSlide>
              ))}
            </Slider>
          </StyledContainer>
          {isVideo && (
            <StyledContainer>
              <StyledVideo
                controls
                height="100%"
                poster="video/poster.webp"
                preload="none"
                src={strapiHome.Video.video_url.localFile.url}
                type="video/mp4"
                width="100%"
              >
                This browser does not support the HTML5 video element.
              </StyledVideo>
            </StyledContainer>
          )}
        </StyledReviewWrapper>
      </animated.div>
    </Section>
  )
}

Opinions.propTypes = {}

Opinions.defaultProps = {}

export default Opinions
