import React, { memo } from "react"

import { graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import styled from "styled-components"

import Icons from "components/common/Icons"
import Slider from "components/common/Slider"

const StyledContainer = styled.div`
  ${({ theme }) => `
  width: 100%;
  margin: 0 auto;
  padding: 7.5rem 0;
  background-color: ${theme.color.graphite};
  `}
`

const StyledHeader = styled.h2`
  ${({ theme }) => `
  display: block;
  margin: 0 !important;
  padding-bottom: 2rem;
  color: ${theme.color.text.inverted};
  font-family: fatfrank,sans-serif;
  font-size: 3.6rem;
  font-weight: 900;
  text-align: center;
  `}
`

const StyledWrapper = styled.div`
  ${({ theme }) => `
  margin: 0 auto 30px;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(6, 1fr);
  max-width: 1140px;
  color: ${theme.color.text.inverted};

  ${theme.mq.s} {
    grid-template-columns: repeat(4, 1fr);
  }

  ${theme.mq.xs} {
    grid-template-columns: repeat(2, 1fr);
    display: none;
  }
  `}
`

const StyledMobileWrapper = styled.div`
  ${({ theme }) => `
    display: none;

    ${theme.mq.xs} {
      display: block;
    }
 `}
`

const StyledCircleWrapper = styled.div`
  position: relative;
  padding: 30px 0;
`

const StyledProportionValue = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;
  font-family: ${({ theme }) => theme.font.fatfrank};
  color: ${({ theme }) => theme.color.text.inverted};
  text-align: center;

  ${({ theme }) => theme.mq.s} {
    font-size: 2.2rem;
  }
`

const StyledCircle = styled.div`
  background-color: ${({ color, theme }) => color || theme.color.white_000};
  border-radius: 100%;
  position: relative;
  height: 8rem;
  width: 8rem;
  margin: 0 auto 30px;
  z-index: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &:before,
  &:after {
    position: absolute;
    content: "";
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: ${({ color, theme }) => color || theme.color.white_000};
    border-radius: 100%;

    ${({ theme }) => theme.mq.s} {
      background-color: ${({ theme }) => theme.color.white_000};
    }
  }

  &:before {
    z-index: -1;
    opacity: 0.42;
    width: 120%;
    height: 120%;
  }
  &:after {
    z-index: -2;
    opacity: 0.42;
    width: 135%;
    height: 135%;
  }

  &:first-child ${StyledProportionValue} {
    color: ${({ theme }) => theme.color.cyan_300};
  }

  ${({ theme }) => theme.mq.s} {
    background-color: ${({ theme }) => theme.color.white_000};

    &:nth-child(2) {
      grid-area: area_3;
    }
  }

  ${({ theme }) => theme.mq.xs} {
    height: 6rem;
    width: 6rem;
  }
`

const StyledStaticImage = styled.div`
  position: absolute;

  img {
    max-width: 50px;
  }

  ${({ theme }) => theme.mq.xs} {
    img {
      max-width: 35px;
    }
  }
`

const query = graphql`
  query techStackQuery {
    strapiTeamExtension {
      TechStack {
        title
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
              fluid(maxWidth: 500, quality: 92) {
                ...GatsbyImageSharpFluid
              }
            }
            extension
            publicURL
          }
        }
      }
    }
  }
`

const TechStack = () => {
  const { strapiTeamExtension } = useStaticQuery(query)

  return (
    <StyledContainer>
      <StyledHeader>Tech Stack we operate in</StyledHeader>
      <StyledWrapper>
        {strapiTeamExtension.TechStack.map(item => (
          <StyledCircleWrapper>
            <StyledCircle>
              <StyledStaticImage>
                <Icons image={item} />
              </StyledStaticImage>
            </StyledCircle>
            <StyledProportionValue>{item && item.title}</StyledProportionValue>
          </StyledCircleWrapper>
        ))}
      </StyledWrapper>
      <StyledMobileWrapper>
        <Slider hideDots slidesToShow={3}>
          {strapiTeamExtension.TechStack.map(item => (
            <StyledCircleWrapper>
              <StyledCircle>
                <StyledStaticImage>
                  <Icons image={item} />
                </StyledStaticImage>
              </StyledCircle>
              <StyledProportionValue>{item && item.title}</StyledProportionValue>
            </StyledCircleWrapper>
          ))}
        </Slider>
      </StyledMobileWrapper>
    </StyledContainer>
  )
}

TechStack.propTypes = {
  data: PropTypes.shape({
    page_title: PropTypes.string.isRequired,
    page_description: PropTypes.string.isRequired,
  }).isRequired,
}

export default memo(TechStack)
